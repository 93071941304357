import React from "react";
// import { BaseComponentProps } from ".";
import { Link } from "react-router-dom";
import logo from "../logo-512.png";
import { getAgent } from "../sys/Global";

function Header() {
  let agent = getAgent();
  let authenticated = agent.authenticated().use_state();

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/login">
          <img alt="Mindbase Logo" src={logo} className="header-logo"></img>
          Mindbase
        </Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto">
            {authenticated.get() &&
              <li className="nav-item">
                <Link className="nav-link" to="/logout">Log Out</Link>
              </li>
            }
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;

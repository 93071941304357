import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Header from '../components/Header';
import Logout from './auth/Logout';
function Main() {
  return (
    <>
      <Switch>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route>
          <Header></Header>
          {/* <Main></Main> */}
          <></>
        </Route>
      </Switch>
    </>
    //    <div
    //    className="App"
    //    onMouseMove={(e) =>
    //      // this.state.agent.updatePresence({ x: e.clientX, y: e.clientY })
    //      1 + 1
    //    }
    //  >
  );
}

export default Main

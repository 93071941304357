import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

// import Agent from "./sys/Agent";
import { registerAgent, getAgent } from "./sys/Global";
// import { register } from "./serviceWorker";
import Main from "./section/Main";
import Auth from "./section/Auth";

// interface Props {}

// interface State {
//   rootTree: Instance<typeof RootModel>;
//   connection: Websocket;
// }

export class App extends React.Component<any, any> {
  componentDidMount() {
    // const connection = new Connection(hardCodedConfig);

    // TODO - Short Order Priorities:
    // [~] 1. user signup (email + keymask + salted passphrase) - Do it the stupid way for now
    //     [X] basic websocket establishment in rust
    //     [X] Rust agent send message with TS websocket connection
    //     [X] Move transport structs to common repo ( not mindbase? )
    //     [X] Basic RPC (Request / Response association)
    //     [X] password alt-hashing
    //     [X] create key and mask
    // [X] 2. user login (email + salted passphrase) ONLY one agentkey/keymask per user right now – Later we can get fancy with multiple keys per user)
    // [ ] 3. Display a trivial graph
    //     [X] basic rust observables in react
    //     [X] observable iterators
    //     [ ] integrate mindbase
    //        [ ] get it running again
    //        [ ] decouple cli crate
    //        [ ] decouple storage engine
    //        [ ] get it compiling in wasm
    //        [ ] proxy sled reads to server
    //      [ ] Concise display logic for a Symbol
    //      [ ] display a single symbol based on pre-formulated MBQL statement
    //     [ ] recursive rust observables in react
    // [ ] 4. Augment a trivial graph
    // [ ] 5. Validate server encryption
    // [ ] 5. Share a trivial subgraph with another user ( pubkey )
    // [ ] 6. Create a trivial automated subgraph augmentation agent
    // [ ] 7. Scratch a real personal itch
    // [ ] 8. Forgot password - Server-assisted Key recovery (store multiple masks of the same key / print backup passkey QR)

    // This is dumb, but it gets the job done
    import("./wasm").then((wasm) => {
      // const agent = wasm.Agent.new();
      let agent: any = wasm.create_agent();
      // agent.free();
      registerAgent(agent);
      this.setState({ agent });

      (window as any).agent = agent.clone();
      agent.authenticated().bind_component(this);
    });
  }
  render() {
    const agent = getAgent();
    if (!agent) {
      return <></>;
    }

    if (!agent.authenticated().get()) {
      return <Auth></Auth>;
    } else {
      return <Main></Main>;
    }
  }
}

export default App;

import React, { useState } from "react";
import { getAgent } from "../../sys/Global";
import { useForm } from "react-hook-form";

import { Link, useHistory } from "react-router-dom";
import WaitCard from "../../components/util/WaitCard";

let i = 0;
function Login(props: any) {
  const { register, handleSubmit, errors } = useForm();
  const agent = getAgent();

  let [stage, setStage] = useState("");
  let history = useHistory();

  const onSubmit = (data: any) => {
    setStage("preparing");
    // TODO 5 - remove this setTimeout once wasm_thread is being used inside .signup
    setTimeout(() => {
      agent
        .clone()
        .login(data.email, data.passphrase)
        .then((res: any) => {
          history.replace("/");
        }).catch((res: any) => {
          console.log("TS got catch: ", res);
          if (res === "declined") {
            setStage("declined");
          }
        });
    }, 30);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        {stage === 'preparing' && <WaitCard message="Preparing credentials..." />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Log In to Mindbase {i++}</h3>
          <br />
          <br />
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              name="email"
              ref={register({ required: true })}
            />
            {errors.email && <span>This field is required</span>}
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="passphrase"
              className="form-control"
              placeholder="Enter password"
              ref={register({ required: true })}
            />
            {errors.passphrase && <span>This field is required</span>}
          </div>
          {stage === 'declined' && <div>Login was declined. Please check your credentials and try again</div>}

          <button type="submit" className="btn btn-primary btn-block">
            Log in to Mindbase
          </button>
          <div className="row no-gutters">
            <div className="col button-tray">
              New to Mindbase? <Link to="/signup">Sign Up for Mindbase</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;

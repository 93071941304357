import React from 'react'
import { useHistory } from 'react-router-dom';
import { getAgent } from '../../sys/Global';

function Logout() {
    getAgent().logout();
    let history = useHistory();
    history.replace("/login");
    return <></>;
}

export default Logout

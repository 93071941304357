import { Agent } from "../wasm";

let last_agent: Agent;

export function registerAgent(agent: Agent) {
  last_agent = agent;
}
export function getAgent(): Agent {
  return last_agent;
}

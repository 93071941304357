import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from '../components/Header';
import Login from './auth/Login';
import Signup from './auth/Signup';

function Auth() {
  return (
    <>
      <Header />
      <Switch>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route>
          <Redirect to="/login"></Redirect>
        </Route>
      </Switch>
    </>
  );
}

export default Auth
import React, { useState } from "react";
import { getAgent } from "../../sys/Global";
import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";
import WaitCard from "../../components/util/WaitCard";

let i = 0;
function Signup() {
  const { register, handleSubmit, errors } = useForm();
  const agent = getAgent();
  let list = agent.query();

  let [stage, setStage] = useState("");


  const onSubmit = (data: any) => {
    setStage("generating");
    // TODO 5 - remove this setTimeout once wasm_thread is being used inside .signup
    setTimeout(() => {
      agent
        .clone()
        .signup(data.email, data.passphrase)
        .then((res: any) => {
          // history.push("/");
        }).catch((res: any) => {
          console.log("TS got catch: ", res);
          if (res === "declined") {
            setStage("declined");
          }
        });
    }, 30);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        {stage === 'generating' && <WaitCard message="Generating Identity..." />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Sign Up for Mindbase {i++}</h3>

          <ul>
            {list.map((v: any) => (
              <li>{v}</li>
            ))}
          </ul>
          <br />
          <br />
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              name="email"
              ref={register({ required: true })}
            />
            {errors.email && <span>This field is required</span>}
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="passphrase"
              className="form-control"
              placeholder="Enter password"
              ref={register({ required: true })}
            />
            {errors.passphrase && <span>This field is required</span>}
          </div>
          {stage === 'declined' && <div>Signup was declined. Try logging in?</div>}

          <button type="submit" className="btn btn-primary btn-block">
            Sign Up for Mindbase
          </button>
          <div className="row no-gutters">
            <div className="col button-tray">
              Already Signed up? <Link to="/login">Log In to Mindbase</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;

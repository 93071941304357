import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

type ModalSpinnerProps = {
    message: string
}

function WaitCard(props: ModalSpinnerProps) {
    return (
        <div className="WaitCard">
                <span className="align-top">{ props.message }</span><br/><br/>
                <Spinner animation="border"></Spinner>
        </div>
    )
}

export default WaitCard
